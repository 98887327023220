/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Precisamente por que el hardware no es igual que el software no podemos aplicarle las mismas libertades que al software. Además el software está bien definido por su código fuente, sin embargo para construir hardware podemos encontrar diversos tipos de código fuente. Yo en principio he encontrado estos tres:"), "\n", React.createElement(_components.p, null, "- ", React.createElement(_components.strong, null, "Hardware reconfigurable:"), " Viene escrito en HDL (", React.createElement(_components.a, {
    href: "http://es.wikipedia.org/wiki/Lenguaje_de_descripci%C3%B3n_de_hardware",
    title: "Lenguaje de descripción de hardware"
  }, "lenguaje de descripción de hardware"), ")."), "\n", React.createElement(_components.p, null, "- ", React.createElement(_components.strong, null, "Software de microcontroladores y autómatas:"), " Aunque realmente no es hardware su relación con él es tan estrecha que lo podemos meter en este grupo. Puede venir escrito en infinidad de lenguajes desde ASM(ensamblador) hasta Grafcet."), "\n", React.createElement(_components.p, null, "-", React.createElement(_components.strong, null, "Hardware estático:"), " Es lo que normalmente entendemos por hardware, la parte física del conjunto. Se puede definir con esquemas , con los planos de circuito impreso y con los archivos para fabricación industrial."), "\n", React.createElement(_components.p, null, "De los tres tipos anteriores podemos aplicar la misma licencia GPL a los dos primeros tipos, pero para el hardware estático tenemos que aclarar antes unos puntos."), "\n", React.createElement(_components.p, null, "El problema está en que el hardware estático no se puede copiar, así que salvamos este problema aplicando la licencia GPL a sus planos ya que consideramos esto como su código fuente, esto sí se puede copiar facilmente, el problema ahora viene al elegir una herramienta para realizar los diseños libre y sobre un SO libre también, pero eso ya es otro tema."), "\n", React.createElement(_components.p, null, "Un saludo"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
